import React, { useState, useEffect } from 'react';
import { Link, Outlet, useNavigate } from "react-router-dom";
import { Button, Modal } from 'react-bootstrap';
import HelperApi from '../../../services/HelperApi';
import Loader from '../../Dashboard-card/Loader';
import { SecondaryButtonInput } from '../../../mainComponent/secondaryButtonInput';
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
export default function HelperList(props) {
    const { doctorId } = props;
    const [helperList, setHelperList] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const [details, setDetails] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const { removeHelper, getHelper } = HelperApi();
    const navigate = useNavigate();

    useEffect(() => {
        getHelperDetails();
    }, [])

    const handleDeleteShow = (details) => {
        setDetails(details)
        setShowDelete(true)
    }

    const handleDeleteClose = () => setShowDelete(false)

    function getHelperDetails() {
        setIsLoading(true);
        getHelper(doctorId)
            .then((result) => {
                if (result) {
                    const data = result.filter((helper) => {
                        if (helper.isDeleted === false) {
                            return helper
                        }
                    })
                    setHelperList(data)
                } else {
                    return null
                }

            })
            .catch((error) => {
                console.error("Failed to fetch data", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    function deleteHelper(details) {
        const id = details._id;
        removeHelper(id)
            .then(() => {
                getHelperDetails()
                handleDeleteClose()
            })
    }

    const handleClick = (e, details) => {
        e.preventDefault()
        navigate(`update/${details._id}`)
    }

    return (
        <div>
            {isLoading ?
                <div className='loader-container'>
                    <Loader />
                </div>
                :
                <>
                    {helperList && helperList.length > 0 ?
                        <div className='row'>
                            <>
                                {helperList && helperList.map((details, i) => {
                                    return (
                                        <div className="col-md-4 mb-4" key={i}>
                                            <div className="mainCards">
                                                <span className='cardSpan'>
                                                    <i className='icon-user color' />
                                                    <b className=' fontSize'>{details.username}</b>
                                                </span>
                                                <span className='cardSpan'>
                                                    <i className='icon-email color' />
                                                    {details.email}
                                                </span>
                                                <span className='cardSpan'>
                                                    <i className='icon-mobile-1 color' />
                                                    {details.mobile}
                                                </span>
                                                <div className="row justify-end top_border">
                                                    <div className="mt-3">
                                                        <Link onClick={(e) => handleClick(e, details)}>
                                                            <MainButtonInput>Edit</MainButtonInput>
                                                        </Link>
                                                    </div>
                                                    <div className='mt-3 ml-2'>
                                                        <Link to="#" onClick={() => handleDeleteShow(details)}>
                                                            <SecondaryButtonInput>Delete</SecondaryButtonInput>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                        </div>
                        : <div className="clinicHistory font_weight" align='center' >Add your assistant here</div>
                    }
                </>
            }

            <Modal show={showDelete} onHide={handleDeleteClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="alert modalColor" >
                        You want to delete this assistant.
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='appColor' variant="default " onClick={() => deleteHelper(details)}>
                        Yes
                    </Button>
                    <Button variant="default" className='borderStyle' onClick={handleDeleteClose}>
                        No
                    </Button>

                </Modal.Footer>
            </Modal>
            <Outlet />
        </div >

    )
}