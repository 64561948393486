import { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { ShowLoginOtp } from "./Partial/showLoginOtp";
import { MainButtonInput } from "../../mainComponent/mainButtonInput";
import AuthApi from "../../services/AuthApi";

export default function LoginDoctor() {
    //for show otp input
    const [mobile, setMobile] = useState("");
    const [loginData, setLoginData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [showOTP, setShowOTP] = useState(false);
    const [message, setMessage] = useState(false)
    const { login } = AuthApi();

    const getOTPSection = (e) => {
        e.preventDefault()
        if (mobile.length < 10) {
            setIsError('Please Enter valid mobile number.')
        }
        else {
            login( mobile )
                .then(response => {
                    // alert(response.data.otp)
                    let item = response.data
                    setLoginData(item)
                    setShowOTP(true)
                    setMessage(true)
                })
        }
    };

    return (
        <main>
            <div className="bg_color_2">
                <div className="container margin_60_35">
                    <div id="login-2">
                        <h1>Reset Mpin</h1>
                        <form >
                            <div className="box_form clearfix">
                                <div className="width50 mb-2 pl-3 appcolor" align="left">
                                    <Link to='/'>Already have account</Link>
                                </div>
                                <div className="box_login last">
                                    <div className="row">
                                        <lable className="mb-2 fontSize">Mobile Number</lable>
                                        <div className="col-md-12 mb-2">
                                            <input 
                                                name="mobile"
                                                value={mobile.mobile}
                                                maxLength={10}
                                                pattern="[+-]?\d+(?:[.,]\d+)?"
                                                onChange={(e) => setMobile(e.target.value)}
                                                placeholder="Phone Number (+XX)"
                                                className="form-control" 
                                            />
                                            {message && (<span className="sendotp-message"> OTP is sent to the mobile number</span>)}
                                        </div>
                                        {showOTP === true ?
                                            <>
                                                <ShowLoginOtp loginData={loginData} />
                                                <Outlet />
                                            </>
                                            : <div className="">
                                                <MainButtonInput onClick={getOTPSection}>Go</MainButtonInput>
                                            </div>
                                        }
                                        <div className="validation mb-2 ml-3">{isError}</div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    )
}