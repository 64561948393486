import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Modal } from 'react-bootstrap';
import CalendarModalBox from './partial/CalendarModalBox';
import { MainNav } from '../../mainComponent/mainNav';
import { Wrapper } from '../../mainComponent/Wrapper';
import UserLinks from './partial/uselinks';
import { setHelperData } from "../../recoil/atom/setHelperData";
import { useRecoilState } from "recoil";
import AppointmentsApi from '../../services/AppointmentsApi';
import Loader from './Loader';
import { Drawer, IconButton, Typography, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
const localizer = momentLocalizer(moment)

export default function Calender(props) {
  const { doctorId } = props;
  const { getAppointmentsDetails } = AppointmentsApi()
  const [getData, setGetData] = useState([])
  const [show, setShow] = useState(false);
  const [patientIdDetails, setPatientIdDetails] = useState([])
  const [helpersData] = useRecoilState(setHelperData)
  const [patientAppointment, setPatientAppointment] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = useMediaQuery('(max-width:  768px)')
  const [open, setOpen] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    handleOnSelectSlot();
  }, [])

  const handleClose = () => {
    setShow(false)
    setOpen(false);
  }

  const handleModalButtonClick = (item) => {
    const patientId = item.patientId
    setPatientAppointment(item)
    setShow(true)
    setOpen(true)
    setPatientIdDetails(patientId)
  }

  const handleOnSelectSlot = () => {
    setIsLoading(true);
    getAppointmentsDetails(doctorId)
      .then((result) => {
        const data = result['test'];
        if (data) {
          const calendarData = []
          data && data.map((item) => {
            if (item.dependentId) {
              calendarData.push({
                title: item['dependentDetails'][0].name,
                patientId: item['dependentDetails'][0]._id,
                id: item._id,
                start: new Date(item.startDate),
                end: new Date(moment(item.startDate).add({ hours: 0, minutes: item.timeSlot }).toString()),
                timeslots: item.timeSlot,
                status: item.status,
              })
            } else {
              calendarData.push({
                title: item['patientDetails'][0].name,
                patientId: item['patientDetails'][0]._id,
                id: item._id,
                start: new Date(item.startDate),
                end: new Date(moment(item.startDate).add({ hours: 0, minutes: item.timeSlot }).toString()),
                timeslots: item.timeSlot,
                status: item.status,
              })
            }
            setGetData(calendarData);
          })
        }
        setIsLoading(false);
      })
  }
  const handleDateClick = (slotInfo) => {
    const selectedDate = moment(slotInfo.start).format('YYYY-MM-DD');
    navigate(`/appointments/${doctorId}/bookappointment`);
  };

  const eventPropGetter = (event) => {
    const backgroundColor = event.status === "Ongoing" ? '#c0d2fc' : '#edebeb';
    const color = event.status === "Ongoing" ? '#333' : '#333';
    return { style: { backgroundColor, color } }
  }

  return (
    <Wrapper>
      <MainNav>
        <ul className="clearfix">
          <li className='float-none' style={{ fontSize: 'inherit' }}>Schedule</li>
        </ul>
      </MainNav>
      <div className="row">
        <div className='width_16'>
          <div className="dash row">
            <UserLinks
              doctorId={doctorId}
              helperId={helpersData._id}
              accessModule={helpersData.access_module}
            />
          </div>
        </div>
        <div className='width_84'>
          {isLoading ?
            <div className='loader-container'>
              <Loader />
            </div>
            :
            <div className="common_box">
              <div className="white-box">
                <div className="myCustomHeight ">
                  <Calendar
                    messages={{
                      agenda: 'Schedule'
                    }}
                    localizer={localizer}
                    events={getData}
                    startAccessor="start"
                    endAccessor="end"
                    onSelectSlot={handleDateClick} // Add the date click handler
                    eventPropGetter={eventPropGetter}
                    showMultiDayTimes={true}
                    selectable={true}
                    onSelectEvent={handleModalButtonClick}
                    style={{ height: 'calc(80vh - 80px)', width: '100%', cursor: 'pointer' }}
                  />
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      {isMobile ? (
        <Drawer anchor="bottom" open={open} onClose={handleClose}>
          <div className='drawerTitle underline' >
            <Typography variant="h6">Patient Details</Typography>
            <IconButton onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
          <div className='p-4'>
            <CalendarModalBox
              patientAppointment={patientAppointment}
              doctorId={doctorId}
              patientId={patientIdDetails}
              onSubmit={handleModalButtonClick}
            />
          </div>
        </Drawer>
      ) : (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title >Patient Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CalendarModalBox
              patientAppointment={patientAppointment}
              doctorId={doctorId}
              patientId={patientIdDetails}
              onSubmit={handleModalButtonClick} />
          </Modal.Body>
        </Modal>
      )}
    </Wrapper>

  )
}