
import React, { useEffect, useState } from "react";
import LabWorkApi from "../../../services/LabWorkApi";
import moment from 'moment';
import { Modal, Button } from "react-bootstrap";

export default function LabWorkView(props) {
    const { labWorkId } = props
    const { getLabWorkById } = LabWorkApi()
    const [data, setData] = useState([])
    const [documents, setDocuments] = useState([])
    const [selectedImage, setSelectedImage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        getLabWorkData();
    }, [])

    const getLabWorkData = () => {
        getLabWorkById(labWorkId)
            .then((res) => {
                setData(res)
                if(res["documents"]){
                    setDocuments(res["documents"])
                }
            })
    }

    const openModal = (url) => {
        setSelectedImage(url);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedImage(null);
    };

    // const nextImage = () => {
    //     setSelectedImage((prevIndex) => (prevIndex + 1) % documents.length);
    // };

    // const prevImage = () => {
    //     setSelectedImage(
    //         (prevIndex) => (prevIndex - 1 + documents.length) % documents.length
    //     );
    // };
    return (
        <div className="patientDataStyle">
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2" >Work Name</label>
                </div>
                <div className="width70">
                    {data.workname}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Work Type</label>
                </div>
                <div className="width70">
                    {data.worktype}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Lab Name</label>
                </div>
                <div className="width70">
                    {data.labname}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Lab Contact</label>
                </div>
                <div className="width70">
                    {data.labcontact}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Patient Name</label>
                </div>
                <div className="width70">
                    {data.patient}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Clinic Name</label>
                </div>
                <div className="width70">
                    {data.clinic}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Tooth Number</label>
                </div>
                <div className="width70">
                    {data.toothnumber}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Given Date</label>
                </div>
                <div className="width70">
                    {moment(data.givendate).format("DD-MM-YYYY")}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Receive Date</label>
                </div>
                <div className="width70">
                    {moment(data.recievedate).format("DD-MM-YYYY")}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Price</label>
                </div>
                <div className="width70">
                    {data.price}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Comment</label>
                </div>
                <div className="width70">
                    {data.comments}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Documents</label>
                </div>
                <div className="width70">
                    {documents && documents.length > 0 && (
                        <div>
                            <h6 align="left" className='mb-2'>Uploaded Files:</h6>
                            <div className='row'>
                                {documents && documents.map((url, index) => (
                                    <div key={index}>
                                        <img
                                            src={url}
                                            alt={`Uploaded file ${index + 1}`}
                                            className='uploadedimg'
                                            onClick={() => openModal(url)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {isModalOpen && (
                <Modal show={isModalOpen} onHide={closeModal}>
                    <Modal.Header closeButton onClick={(e) => e.stopPropagation()}>
                        {/* <button className="prev-btn" onClick={prevImage}>
                            Prev
                        </button>
                        <button className="next-btn" onClick={nextImage}>
                            Next
                        </button> */}
                    </Modal.Header>
                    <Modal.Body>
                        <img src={selectedImage} alt="Selected Image" className="modal-image" />
                    </Modal.Body>
                </Modal>
            )}
        </div >
    )
}