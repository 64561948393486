import React, { useState } from "react";
import { MainInput } from "../mainComponent/mainInput";
import { MainButtonInput } from "../mainComponent/mainButtonInput";
import { setNewPatientId } from "../recoil/atom/setNewPatientId";
import { useRecoilState } from "recoil";
import PatientApi from "../services/PatientApi";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import UserLinks from "../doctor/Dashboard-card/partial/uselinks";
import { MainNav } from "../mainComponent/mainNav";
import { Wrapper } from "../mainComponent/Wrapper";
import { setHelperData } from "../recoil/atom/setHelperData";

function PatientMpin() {
    const { doctorId } = useParams();
    const [mobile, setMobile] = useState("");
    const [isError, setIsError] = useState(false);
    const [password, setPassword] = useState("")
    const [helpersData, setHelpersData] = useRecoilState(setHelperData)
    const { loginPatient } = PatientApi()
    const [patientId, setPatientId] = useRecoilState(setNewPatientId);
    const navigate = useNavigate();

    const handleMpin = (e) => {
        e.preventDefault()
        if (mobile.length < 10) {
            setIsError('Mobile number must be 10 digits')
        }
        else {
            loginPatient({ mobile})
                .then(data => {
                    setPatientId(data.data._id)
                    const _id = data.data._id
                    if (data.data.isLoggedIn === true) {
                        navigate(`/appointments/${doctorId}/patientprofile/${_id}`)
                        setIsError(false)
                    }
                    else {
                        setIsError('You have entered an invalid mobile no.')
                    }

                })
        }
    }
    const handleCreateAcc = () => {
        navigate(`createpatientmpin`)
    }

    return (

        <Wrapper>
            <MainNav>
                <ul className="clearfix">
                    <li>
                        <Link to={`/appointments/${doctorId}`}>
                            <i className="arrow_back backArrow" title="back button"></i>
                        </Link>
                    </li>
                    <li className='float-none' style={{ fontSize: 'inherit' }} >Book Appointments</li>
                </ul>
            </MainNav>
            <div className="row ">
                <div className='width_16'>
                    <div className='dash row'>
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module}
                        />
                    </div>
                </div>
                <div className='width_84 common_box'>
                    <div className="container margin_60_35">
                        <div id="login-2">
                            <form>
                                <h5 className='mb-3 primary-text-color'>Book an appointment</h5>
                                <div className="clearfix">
                                    <div className=" last">
                                        <div className="width_80 ml-2 mr-2 ">
                                            <label className='mb-2'>Mobile Number</label>
                                            <MainInput
                                                type="text"
                                                name="mobile"
                                                value={mobile.mobile}
                                                maxLength={10}
                                                pattern="[+-]?\d+(?:[.,]\d+)?"
                                                onChange={(e) => setMobile(e.target.value)}
                                                placeholder="Phone Number (+XX)">
                                            </MainInput>

                                        </div>
                                        {/* <lable align='left'>Enter MPIN</lable> */}
                                        {/* <div className="width_80 ml-2 mr-2">
                                            <label className='mb-2'>MPIN</label>
                                            <MainInput
                                                className="form-control"
                                                type="password"
                                                name="password"
                                                maxLength={6}
                                                pattern="[+-]?\d+(?:[.,]\d+)?"
                                                onChange={(e) => setPassword(e.target.value)}
                                                placeholder="Enter MPIN"
                                                required>
                                            </MainInput>
                                        </div> */}

                                        <div className="row ml-2">
                                            <div className="mr-2">
                                                <MainButtonInput onClick={handleMpin}>Login</MainButtonInput>
                                            </div>
                                            <div className="">
                                                <Button
                                                    type="submit"
                                                    onClick={() => handleCreateAcc()}
                                                    variant="default"
                                                    className='lightbuttonColor  mr-3 btn_sub'>
                                                    Create Account
                                                </Button>
                                            </div>
                                        </div>
                                        <div align="right" className="mt-2">
                                            <Link to={`forgetpatientmpin`}>Forgot MPIN </Link>
                                        </div>
                                    </div>
                                    <div className="validation mt-2">
                                        {isError}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
            <Outlet />
        </Wrapper >
    )
}
export { PatientMpin }

