import axios from 'axios';
import { API } from '../config'

export default function InventoryApi() {

    const addInward = async (doctorId, clinicId, bodyData) => {
        try {
            const result = await axios.post(`${API}/addinwarddata/${doctorId}/${clinicId}`, bodyData)
            return result.data
        }
        catch (err) {
            return err
        }
    }

    const updateInwardData = async (inwardId, updatedItem) => {
        try {
            const result = await axios.post(`${API}/updateinwarddata/${inwardId}`, updatedItem)
            return result.data
        }
        catch (err) {
            return err
        }
    }
    const editInwardDetails = async (inwardId, bodyData) => {
        try {
            const result = await axios.post(`${API}/editinwarddata/${inwardId}`, bodyData)
            return result.data
        }
        catch (err) {
            return err
        }
    }
    const getInwardDetails = async (doctorId, data) => {
        try {
            const result = await axios.post(`${API}/getinwarddata/${doctorId}`, data)
            return result.data
        }
        catch (err) {
            return err
        }
    }
    const deleteInwardDetails = async (doctorId, clinicId) => {
        try {
            const result = await axios.post(`${API}/deleteinwarddata/${doctorId}/${clinicId}`)
            return result.data
        }
        catch (err) {
            return err
        }
    }
    const getSingleInwardDetails = async (inwardId) => {
        try {
            const result = await axios.get(`${API}/getsingleinwarddata/${inwardId}`)
            return result.data
        }
        catch (err) {
            return err
        }
    }
    const addOutwardData = async (inwardId, bodyData) => {
        try {
            const result = await axios.post(`${API}/addoutwarddata/${inwardId}`, bodyData)
            return result.data
        }
        catch (err) {
            return err
        }
    }
    //inventory item
    const fetchInventoryItem = async () => {
        try {
            const result = await axios.get(`${API}/getinventoryitem`)
            return result.data
        }
        catch (err) {
            return err
        }
    }
    const insertInventoryItem = async (insertItem) => {
        try {
            const result = await axios.post(`${API}/insertinventoryitem`, insertItem)
            return result.data
        }
        catch (err) {
            return err
        }
    }
    //ManufacturerName
    const fetchManufacturerName = async () => {
        try {
            const result = await axios.get(`${API}/getmanufacturerName`)
            return result.data
        }
        catch (err) {
            return err
        }
    }
    const insertManufacturerName = async (insertManufacturer) => {
        try {
            const result = await axios.post(`${API}/insertmanufacturerName`, insertManufacturer)
            return result.data
        }
        catch (err) {
            return err
        }
    }
    //Category     
    const fetchCategory = async () => {
        try {
            const result = await axios.get(`${API}/getcategory`)
            return result.data
        }
        catch (err) {
            return err
        }
    }
    const insertCategory = async (insertcategory) => {
        try {
            const result = await axios.post(`${API}/insertcategory`, insertcategory)
            return result.data
        }
        catch (err) {
            return err
        }
    }
    //SubCategory 
    const fetchSubCategory = async () => {
        try {
            const result = await axios.get(`${API}/getsubcategory`)
            return result.data
        }
        catch (err) {
            return err
        }
    }
    const insertSubCategory = async (insertsubcategory) => {
        try {
            const result = await axios.post(`${API}/insertsubcategory`, insertsubcategory)
            return result.data
        }
        catch (err) {
            return err
        }
    }

    return {
        addInward,
        getInwardDetails,
        editInwardDetails,
        deleteInwardDetails,
        getSingleInwardDetails,
        addOutwardData,
        updateInwardData,
        fetchInventoryItem,
        fetchManufacturerName,
        fetchCategory,
        fetchSubCategory,
        insertInventoryItem,
        insertManufacturerName,
        insertCategory,
        insertSubCategory
    }
}