import React, { useEffect, useState } from "react";
import GetSymptomsData from './GetSymptomsData';
import GetLabPrescription from './getLabPrescription';
import GetMedicinePriscription from './GetMedicinePrescription';
import GetDentalServices from './getdentalservices';
import ReportApi from "../../../services/ReportApi";
import PatientApi from "../../../services/PatientApi";
import AuthApi from "../../../services/AuthApi";
import ClinicApi from "../../../services/ClinicApi";
import moment from "moment";
import wecurifyLogo from '../../../img/small_wecurify_logo@3x.png'
// import fly4smileLogo from '../../../img/fly4smile.png'

export default function PrescriptionSummary(props) {
    const { reportId } = props;
    const { getMedicineReport } = ReportApi();
    const { patientDetailsData } = PatientApi();
    const { getDrInfo } = AuthApi()
    const { getClinicDataById } = ClinicApi()
    const [viewData, setViewData] = useState([]);
    const [patientDetails, setPatientDetails] = useState([]);
    const [doctorDetails, setDoctorDetails] = useState([]);
    const [clinicDetails, setClinicDetails] = useState([]);

    useEffect(() => {
        getMedicineReportData()
    }, [])

    const getMedicineReportData = () => {
        getMedicineReport({ reportId })
            .then((res) => {
                if (res) {
                    setViewData(res[0])
                    const patientId = res[0].patientId
                    const doctorId = res[0].doctorId
                    const clinicId = res[0].clinicId

                    getDrInfo({ doctorId })
                        .then((result) => {
                            setDoctorDetails(result.result[0]);
                        })
                    getClinicDataById({ clinicId })
                        .then((result) => {
                            setClinicDetails(result.data);
                        })
                    patientDetailsData({ patientId })
                        .then((response) => {
                            setPatientDetails(response[0])
                        })
                } else {
                    return <span>Server Error</span>
                }
            })
    }
    return (
        <div className="white-box" >
            <div id="logo_home" align='center' className="mb-2">
                <img className='appLogo' src='/images/fly4smile.png' alt="fly4smiles" />
            </div>

            <div className="p-5 bottom-Border" >
                <div className="row bottom-Border">
                    {clinicDetails ?
                        <div className="row width_60">
                            {/* <figure className="pl-2">
                                <img
                                    className='clinicLogo borderRadius'
                                    src={clinicDetails.clinicLogo}
                                    alt="Clinic Logo"
                                />
                            </figure> */}
                            <div className="pl-2" align="left">
                                {clinicDetails.clinicName ? <div><span className="patientModal">Clinic Name -</span> {clinicDetails.clinicName}</div> : null}
                                {clinicDetails.address ? <div><span className="patientModal">Address -</span> {clinicDetails.address}</div> : null}
                            </div>
                        </div>
                        : null}
                    {doctorDetails ?
                        <div className="pl-2 width_40" align="left">
                            {doctorDetails.name ? <div><span className="patientModal">Dr. {doctorDetails.name}</span></div> : null}
                            {doctorDetails.address ? <div> <span className="patientModal">Address -</span> {doctorDetails.address}</div> : null}
                            {doctorDetails.mobile ? <div><span className="patientModal">Mobile -</span> {doctorDetails.mobile}</div> : null}
                        </div>
                        : null}
                </div>
                <div className="row mt-4">
                    {patientDetails ?
                        <div className="pl-2 width_60" align="left">
                            {patientDetails.name ? <div><span className="patientModal">Patient Name -</span> {patientDetails.name}</div> : null}
                            {patientDetails.email ? <div><span className="patientModal">Email -</span> {patientDetails.email}</div> : null}
                            {patientDetails.gender ? <div><span className="patientModal">Gender -</span> {patientDetails.gender}</div> : null}
                            {patientDetails.age ? <div><span className="patientModal">Age -</span> {patientDetails.age}</div> : null}
                        </div>
                        : null}
                    {viewData ?
                        <div className="pl-2 width_40" align="left">
                            {viewData.height ? <div><span className="patientModal">Height-</span> {viewData.height}</div> : null}
                            {viewData.weight ? <div><span className="patientModal">Weight- </span> {viewData.weight}</div> : null}
                            {viewData.BMI ? <div><span className="patientModal">BMI- </span> {viewData.BMI}</div> : null}
                            {viewData.bp ? <div><span className="patientModal">BP- </span> {viewData.bp}</div> : null}
                            {viewData.pulse ? <div><span className="patientModal">Pulse- </span> {viewData.pulse}</div> : null}
                            {viewData.BMI ? <div><span className="patientModal">Temprature- </span> {viewData.BMI}</div> : null}
                            {viewData.temp ? <div><span className="patientModal">BMI- </span> {viewData.temp}</div> : null}
                        </div>
                        : null}
                </div>

                <GetMedicinePriscription reportId={reportId} />

                <GetLabPrescription reportId={reportId} />

                <GetSymptomsData reportId={reportId} />

                <GetDentalServices reportId={reportId} />

                {viewData && viewData.investigation_note ?
                    <div align="left" className='margin_top_15'>
                        <h6 className='patientModal'>Investigation</h6>
                        <span>{viewData.investigation_note}</span>
                    </div>
                    : null
                }

                {viewData && viewData.premedication_note ?
                    <div className='margin_top_15' align="left">
                        <h6 className='patientModal'>Premedication</h6>
                        <span>{viewData.premedication_note}</span>
                    </div>
                    : null
                }

                {viewData && viewData.new_follow_up ?
                    <div className='margin_top_15' align="left">
                        <h6 className='patientModal'>Next follow up</h6>
                        <span>{moment(viewData.new_follow_up).format("DD-MM-YYYY")}</span>
                    </div>
                    : null
                }
            </div>
            <div className="row m-4">
                <div className="width50" align="left">
                    <div><span className="patientModal">Thanks & Regards</span></div>
                    <div>Dr. {doctorDetails.name}</div>
                </div>
                <div className="width50" align="right">
                    <div>manage by <img className='curifyLogo' src={wecurifyLogo} alt="Something Went Wrong" /></div>
                </div>
            </div>
        </div>
    )
}