import React, { useEffect, useState, useRef } from 'react';
import { setHelperData } from "../../../recoil/atom/setHelperData";
import { Wrapper } from '../../../mainComponent/Wrapper';
import { useRecoilState } from "recoil";
import { Link, useParams } from 'react-router-dom';
import { MainNav } from '../../../mainComponent/mainNav';
import UserLinks from '../partial/uselinks';
import { Button } from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import moment from 'moment';
import ServicesApi from '../../../services/servicesAPI';
import Loader from '../Loader';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@mui/material';

const exportToCSV = (csvData) => {
    // Extracting the column headers from the first object
    const columns = Object.keys(csvData[0]);
    // Generating the CSV header
    let csvContent = columns.join(",") + "\n";
    // Generating CSV rows
    csvData.forEach(item => {
        const row = columns.map(column => {
            const value = item[column].toString().replace(/"/g, '""');
            return `"${value}"`;
        }).join(",");
        csvContent += row + "\n";
    });

    // Creating the CSV file
    const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
}

export default function PaymentList() {
    const { doctorId } = useParams();
    const [helpersData] = useRecoilState(setHelperData)
    const [appointmentList, setAppointmentList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0);
    const { getPaymentDetails } = ServicesApi();
    const pageSize = 5;
    const paymentRef = useRef(currentPage);

    useEffect(() => {
        getPatientDetails(currentPage);
    }, [])

    const handleExport = () => {
        // Payment List data
        if (appointmentList) {
            const payments = appointmentList.map(payment => ({
                patientName: payment["patientDetails"][0].name,
                ModeofPayment: payment["paymentList"][0].paymentMethod,
                Amount: payment["paymentList"][0].amount,
                PaymentDate: payment["paymentList"][0].created_at,
                Status: payment["paymentList"][0].status
            }));

            exportToCSV(payments);
        } else {
            return null
        }
    };

   
    function getPatientDetails(currentPage) {
        setIsLoading(true);
        const data = {
            page: currentPage,
            pageSize: pageSize,
            status: "Completed"
        }
        getPaymentDetails(doctorId, data)
            .then((result) => {
                if(result){
                    setAppointmentList(result.data)
                }
                setTotalPages(result.totalPages)
            })
            .catch((error) => {
                console.error("Failed to fetch data", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handlePageClick = (data) => {
        paymentRef.current = data.selected + 1;
        setCurrentPage(data.selected + 1)
        getPatientDetails(data.selected + 1)
    }

    return (
        <>
            <Wrapper>
                <MainNav>
                    <div className="clearfix row">
                        <div className="width50">
                            <ul className="clearfix">
                                <li className='float-none' style={{ fontSize: 'inherit' }}>Report</li>
                            </ul>
                        </div>
                    </div>
                </MainNav>

                <div className='row'>
                    <div className='width_16'>
                        <div className='dash row'>
                            <UserLinks
                                doctorId={doctorId}
                                helperId={helpersData._id}
                                accessModule={helpersData.access_module}
                            />
                        </div>
                    </div>
                    <div className='width_84'>
                        {isLoading ?
                            <div className='loader-container'>
                                <Loader />
                            </div>
                            :
                            <div className='common_box'>
                                {appointmentList && appointmentList.length > 0 ?
                                    <>
                                        <div className='row justify-end mt-2 mr-5'>
                                            <div>
                                                <label className="font_weight " value="month">Export</label>
                                                <div>
                                                    <Button
                                                        type="submit"
                                                        onClick={handleExport}
                                                        variant="default"
                                                        className='lightbuttonColor  mr-3 btn_sub'>
                                                        <i className="icon-download-1" style={{ fontSize: 15 }} />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <TableContainer component={Paper}>
                                            <Table >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className="font_weight" align="center">Patient Name</TableCell>
                                                        <TableCell className="font_weight" align="center">Mode of Payment </TableCell>
                                                        <TableCell className="font_weight" align="center">Amount</TableCell>
                                                        <TableCell className="font_weight" align="center">Payment Date</TableCell>
                                                        <TableCell className="font_weight" align="center">Status</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {appointmentList && appointmentList.map((item, index) => {
                                                        return (
                                                            <>
                                                                {item["paymentList"][0] ?
                                                                    <TableRow key={index}>
                                                                        <TableCell align="center">
                                                                            {item["patientDetails"][0].name}
                                                                        </TableCell>

                                                                        <TableCell align="center">
                                                                            {item["paymentList"][0].paymentMethod}
                                                                        </TableCell>

                                                                        <TableCell align="center">
                                                                            {item["paymentList"][0].totalAmount}
                                                                        </TableCell>

                                                                        <TableCell align="center">
                                                                            {moment(item["paymentList"][0].created_at).format("DD-MM-YYYY")}
                                                                        </TableCell>

                                                                        <TableCell align="center">
                                                                            {item["paymentList"][0].status}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    : null
                                                                }
                                                            </>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer >
                                        <div className='mt-2'>
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel="Next >"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={5}
                                                pageCount={totalPages}
                                                previousLabel="< Previous"
                                                renderOnZeroPageCount={null}
                                                marginPagesDisplayed={2}
                                                containerClassName="pagination "
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                activeClassName="active"
                                                forcePage={currentPage - 1}
                                            />
                                        </div>
                                    </>
                                    :
                                    <div className="clinicHistory font_weight" >Payments records unavailable</div>
                                }
                            </div>}
                    </div>
                </div>
            </Wrapper>
        </>
    )
}