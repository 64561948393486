import React, { useEffect, useState } from "react";
import {  useNavigate, useParams, Outlet } from "react-router-dom";
import UserLinks from "./partial/uselinks";
import { Wrapper } from "../../mainComponent/Wrapper";
import { MainNav } from "../../mainComponent/mainNav";
import { useRecoilState } from "recoil";
import { setHelperData } from "../../recoil/atom/setHelperData";
import { Button, Modal } from "react-bootstrap";
import SubscriptionApi from "../../services/SubscriptionApi";
import { FaRupeeSign } from "react-icons/fa";
import { THEME_COLOR } from '../../config'
export default function SubscriptionCard() {
    const { updateSubscriptionData,
        getSubscriptionData,
        getSubscriptionPlan,
        subscriptionPDF } = SubscriptionApi();
    const [getSubData, setGetSubData] = useState([]);
    const [getPlan, setGetPlan] = useState(null);
    const [subscriptionId, setSubscriptionId] = useState([]);
    const [duration, setDuration] = useState('')
    const { doctorId } = useParams();
    const [helpersData] = useRecoilState(setHelperData);
    const [getSubscription, setGetSubscription] = useState([])
    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchSubscription()
        getSubscriptionPlans()
    }, []);

    const fetchSubscription = () => {
        getSubscriptionData({ doctorId })
            .then((res) => {
                if (res) {
                    const data = res.filter((item) => {
                        if (item.Status === "Running") {
                            return item
                        }
                    })
                    setDuration(data[0].duration)
                    setGetSubData(data[0].selected_plan)
                    setSubscriptionId(data[0]._id)
                } else {
                    return (
                        <div className="mt-5">Not get any subscription</div>
                    )
                }

            })
    }
    const handleShow = (item) => {
        setGetPlan(item)
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
    }

    const confirmInputHandler = (plan) => {
        const bodyData = {
            "doctorId": doctorId,
            "date": new Date(),
            "expiryDate": new Date(),
            "plan": plan.name,
            "duration": plan.frequency,
            "status": "Running",
            "features": plan.features,
            "amount": plan.amount,
        }
        updateSubscriptionData({ subscriptionId }, bodyData)
        subscriptionPDF(subscriptionId)
            .then((res) => {
                setGetSubData(plan)
            })
        navigate(`confirm`)
        handleClose()

    }
    const getSubscriptionPlans = () => {
        getSubscriptionPlan()
            .then((res) => {
                subscriptionPlan(res)
            })
    }
    const subscriptionPlan = (res) => {
        if (res) {
            const data = res.filter((sub) => {
                if (sub.status === true) {
                    return (sub)
                }
            })
            setGetSubscription(data)
        } else {
            return (
                <div className="mt-5">Not get any subscription</div>
            )
        }
    }

    return (
        <Wrapper>
            <MainNav>
                <ul className="clearfix">
                    <li className='float-none' style={{ fontSize: 'inherit' }} >Subscription</li>
                </ul>
            </MainNav>
            <div className="row ">
                <div className='width_16'>
                    <div className="dash row">
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module}
                        />
                    </div>
                </div>
                <div className='width_84'>
                    <div className=" common_box">
                        {getSubscription && getSubscription.length > 0 ?
                            <>
                                <div className='row justify-content'>
                                    {getSubscription && getSubscription.map((item, i) => {
                                        return (
                                            <div className="whiteCard col-3" key={i}>
                                                <span>
                                                    <h4 className="add_top_20 ">{item.name}</h4>
                                                </span>
                                                <h5> <FaRupeeSign />-{item.amount}</h5>
                                                <ul className="cardListScroll underline" >
                                                    {item.features.map((data, i) => {
                                                        return (
                                                            <li key={i} className="card-list">
                                                                <i className="icon-right-circled" title="right-tick"></i>
                                                                {data}
                                                            </li>
                                                        )
                                                    })}

                                                </ul>
                                                {getSubData === item.name ?
                                                    <button
                                                        onClick={handleClose}
                                                        className="btn disabled-card add_bottom_15 shadow-none disabled"
                                                    >Subscribed
                                                    </button>
                                                    :
                                                    item.frequency < parseInt(duration) ?
                                                        <button
                                                            onClick={handleClose}
                                                            className="btn disabled-card add_bottom_15 shadow-none disabled"
                                                        >Get Started
                                                        </button>
                                                        : <button
                                                            onClick={() => handleShow(item)}
                                                            className="sub-card-btn add_bottom_15 shadow-none btn btn-primary">
                                                            Get Started
                                                        </button>
                                                }

                                            </div>
                                        )
                                    })}
                                </div>

                                <Modal show={show} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Are you sure?</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="alert modalColor" >
                                            You want to get this subscription.
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="default" className='appColor' onClick={() => confirmInputHandler(getPlan)}>
                                            Yes
                                        </Button>
                                        <Button variant="default" className='borderStyle' onClick={() => handleClose()}>
                                            No
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </> :
                            <div className="mt-5">Not get any subscription</div>}
                    </div>
                </div>
            </div>
            <Outlet />
        </Wrapper>
    )
}