import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MainButtonInput } from "../../mainComponent/mainButtonInput";
import { MainInput } from "../../mainComponent/mainInput";
import AuthApi from "../../services/AuthApi";
import { useRecoilState } from "recoil";
import { setDoctorId } from "../../recoil/atom/setDoctorId";
import { Button } from "react-bootstrap";

export default function MpinForm() {
    //for show otp input
    const [mobile, setMobile] = useState("");
    const [id, setId] = useRecoilState(setDoctorId)
    const [error, setError] = useState(false);
    const [password, setPassword] = useState('');
    const { signIn } = AuthApi();
    const navigate = useNavigate()

    useEffect(() => {
        setId('')
    }, [])

    const handleSignInSubmit = (e) => {
        e.preventDefault();
        if (mobile.length < 10) {
            setError('Mobile number must be 10 digits');
            return;
        }
        if (!password) {
            setError('Password cannot be empty');
            return;
        }
        signIn({ mobile, password })
            .then(response => {
                if (response.data.status) {
                    setError(response.status.error || 'You have entered an invalid credentails');
                } else {
                    setId(response.data._id)
                    const doctorId = response.data._id;
                    if (response.data.isSubscribed) {
                        navigate(`/profile/${doctorId}`);
                    } else {
                        navigate(`/subscriptions/${doctorId}`);
                    }
                }
            })
            .catch(err => {
                console.error('Sign-in error:', err);
                setError('You have entered an invalid credentails');
            });
    };



    const createAccount = () => {
        navigate('/create')
    }

    return (
        <main>
            <div className="bg_color_2">
                <div className="container margin_60_35">
                    <div id="login-2">
                        <h1>login to fly4smiles</h1>
                        <form >
                            <div className=" clearfix">
                                <div className="last">
                                    <div className="mb-2 pl-3" align='right'>
                                        <Link to='/helperlogin'>Login As Assistant</Link>
                                    </div>
                                    <div className="row ">
                                        <lable className='mb-2 fontSize'>Mobile Number</lable>
                                        <div className="col-md-12 mb-2">
                                            <input
                                                name="mobile"
                                                value={mobile.mobile}
                                                className="form-control"
                                                maxLength={10}
                                                pattern="[+-]?\d+(?:[.,]\d+)?"
                                                onChange={(e) => setMobile(e.target.value)}
                                                placeholder="Phone Number (+XX)" />
                                        </div>

                                        <lable className='mb-2 mt-2 fontSize'>Enter MPIN</lable>
                                        <div className="col-md-12 ">
                                            <MainInput
                                                className="form-control"
                                                type="password"
                                                name="password"
                                                maxLength={6}
                                                pattern="[+-]?\d+(?:[.,]\d+)?"
                                                onChange={(e) => setPassword(e.target.value)}
                                                placeholder="Password"
                                                required>
                                            </MainInput>
                                        </div>
                                        <div className="row">
                                            <div className="mr-2">
                                                <MainButtonInput onClick={handleSignInSubmit}>Login</MainButtonInput>
                                            </div>
                                            {/* <div className="">
                                                <Button
                                                    type="submit"
                                                    onClick={() => createAccount()}
                                                    variant="default"
                                                    className='lightbuttonColor  mr-3 btn_sub'>
                                                    Create Account
                                                </Button>
                                            </div> */}

                                            <div className="mt-2" align='right'>
                                                <Link to='/forgetmpin'>Forget MPIN</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="validation mt-2">
                                    {error}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    )
}