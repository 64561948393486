import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { setDoctorId } from "../../../recoil/atom/setDoctorId";
import { useRecoilState } from "recoil";
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import ControlPointRoundedIcon from '@mui/icons-material/ControlPointRounded';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

export default function UserLinks(props) {
    const { helperId, accessModule } = props;
    const [doctorId] = useRecoilState(setDoctorId)
    const location = useLocation();
    const [isReportsActive, setIsReportsActive] = useState(false);
    const [isInventoryActive, setIsInventoryActive] = useState(false);

    // Update the state based on the current URL
    useEffect(() => {
        if (location.pathname.includes(`/reports/:doctorId`)) {
            setIsReportsActive(true);
        } else {
            setIsReportsActive(false);
        }
        if (location.pathname.includes(`/inventory/:doctorId`)) {
            setIsInventoryActive(true);
        } else {
            setIsInventoryActive(false);
        }
    }, [location.pathname]);

    const toggleReportsMenu = () => {
        setIsReportsActive(prevState => !prevState);
    };
    const toggleInventoryMenu = () => {
        setIsInventoryActive(prevState => !prevState);
    };

    return (
        <div className="sidemenu rightborder" align='left'>
            {!helperId ?
                <NavLink
                    className={({ isActive }) => (isActive ? "Nav-active " : 'none')}
                    to={`/profile/${doctorId}`}>
                    <div className="dashboard">
                        <PersonIcon className="fontSize " />
                        <b className='fontSize'> Dashboard</b>
                    </div>
                </NavLink>
                :
                <>
                    {
                        accessModule.map((item) => {
                            return (
                                (item.moduleName === "Profile-Management") === true ?
                                    <NavLink
                                        className={({ isActive }) => (isActive ? "Nav-active " : 'none')}
                                        to={`/profile/${doctorId}`}>
                                        <div className="dashboard">
                                            <PersonIcon className="fontSize " />
                                            <b className="fontSize"> Dashboard</b>
                                        </div>
                                    </NavLink>
                                    : null
                            )
                        })
                    }
                </>
            }
            {!helperId ?
                <NavLink
                    className={({ isActive }) => (isActive ? "Nav-active " : 'none')}
                    to={`/appointments/${doctorId}`}>
                    <div className="dashboard ">
                        <AccessTimeRoundedIcon style={{ fontSize: 20 }} />
                        <b className="fontSize">  Appointments</b>
                    </div>
                </NavLink>
                :
                <>
                    {
                        accessModule.map((item) => {
                            return (
                                (item.moduleName === "Appointments") === true ?
                                    <NavLink
                                        className={({ isActive }) => (isActive ? "Nav-active " : 'none')}
                                        to={`/appointments/${doctorId}`}>
                                        <div className="dashboard">
                                            {<AccessTimeRoundedIcon style={{ fontSize: 20 }} />}
                                            <b className="fontSize">  Appointments</b>
                                        </div>
                                    </NavLink>
                                    :
                                    null
                            )
                        })
                    }
                </>
            }

            {
                !helperId ?
                    <NavLink
                        className={({ isActive }) => (isActive ? "Nav-active " : 'none')}
                        to={`/history/${doctorId}`}>
                        <div className="dashboard">
                            <PeopleIcon style={{ fontSize: 20 }} />
                            <b className="fontSize">  Appointments History</b>
                        </div>
                    </NavLink>
                    :
                    <>
                        {
                            accessModule.map((item) => {
                                return (
                                    (item.moduleName === "Appointments") === true ?
                                        <NavLink
                                            className={({ isActive }) => (isActive ? "Nav-active " : 'none')}
                                            to={`/history/${doctorId}`}>
                                            <div className="dashboard">
                                                <PeopleIcon style={{ fontSize: 20 }} />
                                                <b className="fontSize">  Appointments History</b>
                                            </div>
                                        </NavLink>
                                        : null
                                )
                            })
                        }
                    </>
            }
            {
                !helperId ?
                    <NavLink
                        className={({ isActive }) => (isActive ? "Nav-active " : 'none')}
                        to={`/addlab/${doctorId}`}
                    >

                        <div className="dashboard">
                            <i className="icon-hospital" style={{ fontSize: 20 }} />
                            <b className="fontSize">Lab Work</b>
                        </div>
                    </NavLink>
                    :
                    <>
                        {
                            accessModule.map((item) => {
                                return (
                                    (item.moduleName === "LabWork") === true ?

                                        <NavLink
                                            className={({ isActive }) => (isActive ? "Nav-active " : 'none')}
                                            to={`/addlab/${doctorId}`}
                                        >
                                            <div className="dashboard">
                                                <i className="icon-hospital" style={{ fontSize: 20 }} />
                                                <b className="fontSize">Lab Work</b>
                                            </div>
                                        </NavLink>
                                        : null
                                )
                            })
                        }
                    </>
            }

            {
                !helperId ?
                    <NavLink
                        className={({ isActive }) => (isActive ? "Nav-active " : 'none')}
                        to={`/dashboard/${doctorId}`}
                    >
                        <div className="dashboard">
                            <CalendarTodayIcon style={{ fontSize: 20 }} />
                            <b className="fontSize">  Calender</b>
                        </div>
                    </NavLink>
                    :
                    <>
                        {
                            accessModule.map((item) => {
                                return (
                                    (item.moduleName === "Appointments") === true ?
                                        <NavLink
                                            className={({ isActive }) => (isActive ? "Nav-active " : 'none')}
                                            to={`/dashboard/${doctorId}`}
                                        >
                                            <div className="dashboard">
                                                <CalendarTodayIcon style={{ fontSize: 20 }} />
                                                <b className="fontSize">  Calender</b>
                                            </div>
                                        </NavLink>
                                        : null
                                )
                            })
                        }
                    </>
            }

            {/* {
                !helperId ?
                    <NavLink
                        className={({ isActive }) => (isActive ? "Nav-active " : 'none')}
                        to={`/subscription/${doctorId}`}
                    >
                        <div className="dashboard">
                            <AttachMoneyRoundedIcon style={{ fontSize: 20 }} />
                            <b className="fontSize"> Subscription</b>
                        </div>
                    </NavLink>
                    :
                    <>
                        {
                            accessModule.map((item) => {
                                return (
                                    (item.moduleName === "Profile-Management") === true ?
                                        <NavLink
                                            className={({ isActive }) => (isActive ? "Nav-active " : 'none')}
                                            to={`/subscription/${doctorId}`}
                                        >
                                            <div className="dashboard">
                                                <AttachMoneyRoundedIcon style={{ fontSize: 20 }} />
                                                <b className="fontSize"> Subscription</b>
                                            </div>
                                        </NavLink>
                                        :
                                        null
                                )
                            })
                        }
                    </>
            } */}

            {
                !helperId ?
                    <NavLink
                        className={({ isActive }) => (isActive ? "Nav-active " : 'none')}
                        to={`/newdoctor/${doctorId}`}
                    >
                        <div className="dashboard">
                            <ControlPointRoundedIcon style={{ fontSize: 20 }} />
                            <b className="fontSize"> Add Doctors</b>
                        </div>
                    </NavLink>
                    :
                    <>
                        {
                            accessModule.map((item) => {
                                return (
                                    (item.moduleName === "Profile-Management") === true ?
                                        <NavLink
                                            className={({ isActive }) => (isActive ? "Nav-active " : 'none')}
                                            to={`/newdoctor`}
                                        >
                                            <div className="dashboard">
                                                <ControlPointRoundedIcon style={{ fontSize: 20 }} />
                                                <b className="fontSize"> Add Doctors</b>
                                            </div>
                                        </NavLink>
                                        :
                                        null
                                )
                            })
                        }
                    </>
            }


            {
                helperId ? null :
                    <NavLink
                        className={({ isActive }) => (isActive ? "Nav-active " : 'none')}
                        to={`/helper/${doctorId}`}
                    >
                        <div className="dashboard">
                            <ControlPointRoundedIcon style={{ fontSize: 20 }} />
                            <b className="fontSize">  Assistant</b>
                        </div>
                    </NavLink>
            }

            {/* {
                !helperId ?
                    <NavLink
                        className={({ isActive }) => (isActive ? "Nav-active " : 'none')}
                        to={`/medicinelist/${doctorId}`}
                    >
                        <div className="dashboard">
                            <i className="icon-medkit" style={{ fontSize: 20 }} />
                            <b className="fontSize">  Medicine List</b>
                        </div>
                    </NavLink>
                    :
                    <>
                        {
                            accessModule.map((item) => {
                                return (
                                    (item.moduleName === "Medicine") === true ?
                                        <NavLink
                                            className={({ isActive }) => (isActive ? "Nav-active " : 'none')}
                                            to={`/medicinelist/${doctorId}`}
                                        >
                                            <div className="dashboard">
                                                <i className="icon-medkit" style={{ fontSize: 20 }} />
                                                <b className="fontSize">  Medicine List</b>
                                            </div>
                                        </NavLink>
                                        : null
                                )
                            })
                        }
                    </>
            } */}

            <NavLink
                className={`${isReportsActive ? "Nav-active" : "none"}`}
                onClick={toggleReportsMenu}
            >
                <div className="dashboard">
                    <i className="icon-doc" style={{ fontSize: 20 }} />
                    <b className="fontSize">Reports</b>
                    {isReportsActive ?
                        <KeyboardArrowDownOutlinedIcon style={{ fontSize: 25, marginLeft: 60 }} />
                        :
                        <ArrowForwardIosIcon style={{ fontSize: 15, marginLeft: 60 }} />
                    }
                </div>
            </NavLink>

            {
                !helperId ?
                    <div>
                        {isReportsActive && (
                            <div className="sub-menu">
                                <NavLink
                                    className={({ isActive }) => (isActive ? "Nav-active" : 'none')}
                                    to={`/reports/${doctorId}/paymentlist`}>

                                    <div className="dashboard">
                                        <AttachMoneyRoundedIcon className="ml-4" style={{ fontSize: 20 }} />
                                        <b className="fontSize">Payment</b>
                                    </div>
                                </NavLink>
                                <NavLink
                                    className={({ isActive }) => (isActive ? "Nav-active" : 'none')}
                                    to={`/reports/${doctorId}/labworklist`}
                                >
                                    <div className="dashboard">
                                        <i className="ml-4 icon-doc" style={{ fontSize: 20 }} />
                                        <b className="fontSize">Lab Work</b>
                                    </div>
                                </NavLink>
                                <NavLink
                                    className={({ isActive }) => (isActive ? "Nav-active" : 'none')}
                                    to={`/reports/${doctorId}/reportlist`}
                                >
                                    <div className="dashboard">
                                        <i className="ml-4 mr-1 pe-7s-graph2" style={{ fontSize: 20 }} />
                                        <b className="fontSize">Reports</b>
                                    </div>
                                </NavLink>
                            </div>
                        )}
                    </div>
                    :
                    <>
                        {
                            accessModule.map((item) => {
                                return (
                                    (item.moduleName === "Reports") === true ?
                                        <div>
                                            {isReportsActive && (
                                                <div className="sub-menu">
                                                    <NavLink
                                                        className={({ isActive }) => (isActive ? "Nav-active" : 'none')}
                                                        to={`/reports/${doctorId}/paymentlist`}
                                                    >
                                                        <div className="dashboard">
                                                            <AttachMoneyRoundedIcon className="ml-4" style={{ fontSize: 20 }} />
                                                            <b className="fontSize">Payment</b>
                                                        </div>
                                                    </NavLink>
                                                    <NavLink
                                                        className={({ isActive }) => (isActive ? "Nav-active" : 'none')}
                                                        to={`/reports/${doctorId}/labworklist`}
                                                    >
                                                        <div className="dashboard">
                                                            <i className="ml-4 icon-doc" style={{ fontSize: 20 }} />
                                                            <b className="fontSize">Lab Work</b>
                                                        </div>
                                                    </NavLink>
                                                    <NavLink
                                                        className={({ isActive }) => (isActive ? "Nav-active" : 'none')}
                                                        to={`/reports/${doctorId}/reportlist`}
                                                    >
                                                        <div className="dashboard">
                                                            <i className="ml-4 pe-7s-graph2" style={{ fontSize: 20 }} />
                                                            <b className="fontSize">Reports</b>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            )}
                                        </div>
                                        : null
                                )
                            })
                        }
                    </>
            }
            <NavLink
                className={`${isInventoryActive ? "Nav-active" : "none"}`}
                onClick={toggleInventoryMenu}>
                <div className="dashboard">
                    <i className="icon-doc-text-inv" style={{ fontSize: 20 }} />
                    <b className="fontSize">Inventory</b>
                    {isInventoryActive ?
                        <KeyboardArrowDownOutlinedIcon style={{ fontSize: 25, position: 'relative', left: '50' }} />
                        :
                        <ArrowForwardIosIcon style={{ fontSize: 15, position: 'relative', left: '50' }} />
                    }
                </div>
            </NavLink>
            {
                !helperId ?
                    <div>
                        {isInventoryActive && (
                            <div className="sub-menu">
                                <NavLink
                                    className={({ isActive }) => (isActive ? "Nav-active" : 'none')}
                                    to={`/inventory/${doctorId}/inwards`}
                                >
                                    <div className="dashboard">
                                        <i className="ml-4 icon-doc" style={{ fontSize: 20 }} />
                                        <b className="fontSize">In Stock</b>
                                    </div>
                                </NavLink>
                                <NavLink
                                    className={({ isActive }) => (isActive ? "Nav-active" : 'none')}
                                    to={`/inventory/${doctorId}/outwards`}
                                >
                                    <div className="dashboard">
                                        <i className="ml-4 mr-1 pe-7s-graph2" style={{ fontSize: 20 }} />
                                        <b className="fontSize">Stock Report</b>
                                    </div>
                                </NavLink>
                            </div>
                        )}
                    </div>
                    :
                    <>
                        {
                            accessModule.map((item) => {
                                return (
                                    (item.moduleName === "Reports") === true ?
                                        <div>
                                            {isInventoryActive && (
                                                <div className="sub-menu">
                                                    <NavLink
                                                        className={({ isActive }) => (isActive ? "Nav-active" : 'none')}
                                                        to={`/inventory/${doctorId}/inwards`}
                                                    >
                                                        <div className="dashboard">
                                                            <AttachMoneyRoundedIcon className="ml-4" style={{ fontSize: 20 }} />
                                                            <b className="fontSize">In Stock</b>
                                                        </div>
                                                    </NavLink>
                                                    <NavLink
                                                        className={({ isActive }) => (isActive ? "Nav-active" : 'none')}
                                                        to={`/inventory/${doctorId}/outWards`}
                                                    >
                                                        <div className="dashboard">
                                                            <i className="ml-4 icon-doc" style={{ fontSize: 20 }} />
                                                            <b className="fontSize">Stock Report</b>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            )}
                                        </div>
                                        : null
                                )
                            })
                        }
                    </>
            }
        </div >

    )
}  
