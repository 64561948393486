import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SubscriptionApi from '../../../src/services/SubscriptionApi'
import { Button, Modal } from "react-bootstrap";
import { FaRupeeSign } from "react-icons/fa";
import { useRecoilState } from "recoil";
import { setsubscriptionId } from "../../recoil/atom/setSubscriptionId";
export default function Subscription() {
    const { subscription, getSubscriptionPlan } = SubscriptionApi()
    const [show, setShow] = useState(false);
    const [getSubData, setGetSubData] = useState([])
    const [getSubscription, setGetSubscription] = useState([])
    const [subscriptionId, setSubscriptionId] = useRecoilState(setsubscriptionId)
    const { doctorId } = useParams();
    const navigate = useNavigate()

    useEffect(() => {
        getSubscriptionPlans()
    }, [])

    const handleShow = (item) => {
        setShow(true)
        setGetSubData(item)
    }

    const handleClose = () => setShow(false)

    const confirmInputHandler = (plan) => {
        const bodyData = {
            "doctorId": doctorId,
            "date": new Date(),
            "expiryDate": new Date(),
            "plan": plan.name,
            "duration": plan.frequency,
            "status": "Running",
            "features": plan.features,
            "amount": plan.amount,
        }
        subscription(bodyData)
            .then((res) => {
                setSubscriptionId(res._id)
                navigate(`/profile/${doctorId}/edit`)
            })
    }

    const getSubscriptionPlans = async () => {
        await getSubscriptionPlan()
            .then((res) => {
                if (res) {
                    const data = res.filter((sub) => {
                        if (sub.status === true) {
                            return (sub)
                        }
                    })
                    setGetSubscription(data)
                } else {
                    return (
                        <div className="mt-5">Not get any subscription</div>
                    )
                }

            })
    }

    return (
        <main>
            {getSubscription && getSubscription.length > 0 ?
                <>
                    <div className="bg_color_2 ">
                        <div className="container width_100">
                            <div className="subscription">
                                <h2>Ready to get Started?</h2>
                            </div>
                            <div className="">
                                <div className="row justify-content">
                                    {getSubscription && getSubscription.map((item, i) => {
                                        return (
                                            <div className="whiteCard col-3" key={i}>
                                                <span>
                                                    <h4 className="add_top_20">{item.name}</h4>
                                                </span>
                                                <h5> <FaRupeeSign />-{item.amount}</h5>
                                                <ul className=" card-text cardListScroll underline" >
                                                    {item.features.map((data, i) => {
                                                        return (
                                                            <li key={i} className="card-list">
                                                                <i className="icon-right-circled" title="right-tick"></i>
                                                                {data}
                                                            </li>
                                                        )
                                                    })}

                                                </ul>
                                                <button
                                                    onClick={() => handleShow(item)}
                                                    className=" sub-card-btn add_bottom_15 shadow-none btn btn-primary">
                                                    Get Started
                                                </button>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Are you sure?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="alert modalColor" >You want to get this subscription. </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="default" className='appColor' onClick={() => confirmInputHandler(getSubData)}>
                                Yes
                            </Button>
                            <Button variant="default" className='borderStyle' onClick={handleClose}>
                                No
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </> :
                <div className="mt-5">Not get any subscription</div>
            }
        </main>
    )
}